import React from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Vehicles from 'features/pricesRealized/vehicles';
import YouTubePlayer from 'components/YoutubePlayer';
import HeroSection from 'components/HeroSection';
import PDFApplication from 'static/pdf/FL20_Prices_Realized.pdf';
import BannerImage from 'static/images/prices-realized-hero.png';
import MobileBannerImage from 'static/images/prices-realized-mobile-hero.png';
import styles from './prices-realized.module.scss';

const buttons = [
  {
    title: 'Download PDF',
    onClick: () => {
      const windowRef = typeof window !== `undefined` ? window : null;
      windowRef.open(PDFApplication);
    },
  },
];

const PricesRealized = () => (
  <Layout transparentHeader={true} paddingTop={false}>
    <SEO title="Prices Realized" />
    <div className={styles.videoWrapper}>
      <HeroSection
        key={`pricesRealizedHeroSection`}
        description="January 17 & 18, 2020"
        imagePath={BannerImage}
        mobileImagePath={MobileBannerImage}
        title="THE SCOTTSDALE AUCTION"
        leftButtons={buttons}
        subTitles={[
          { title: 'Prices Realized' },
          { title: 'Total Sold: $36,169, 560', bold: true },
          { title: '94% Sell-Through Rate', bold: true },
        ]}
      />

      <div className={styles.videoSection}>
        <YouTubePlayer />
      </div>
    </div>

    <Vehicles />
  </Layout>
);

export default PricesRealized;
